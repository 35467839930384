<template>
    <div class="container-box">
        <el-card class="box-card-bottom">
            <div class="step" style="margin-bottom: 0.1rem;">
                    <span @click="nextStep()">下一步</span>
            </div>
            <el-tabs v-model="activeName1" type="border-card" class="demo-tabs" @tab-click="handleChildClick">
                <div class="right-box">
                    <!-- <button class="L_btn" @click="getUpload">报名信息上传</button> -->
                    <!-- <upload :callbackFile="callbackValue" :tip="tip" /> -->
                    <button class="L_btn" @click="getExcel">报名模板下载</button>
                    <upload :callbackFile="callbackValue" :tip="tip" />
                </div>
                <el-tab-pane label="信息通信工程实践" name="third">
                    <!-- <div class="formWeight">
                            <el-form :inline="true" ref="ruleFormRef" :model="formInline" class="demo-form-inline">
                                <el-form-item>
                                    <el-input v-model="formInline.name" placeholder="请输入姓名" :prefix-icon='Search'
                                        style="width: 1.5rem;height: 0.42rem;" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button style="background-color:#436E8A;color:white;"
                                        @click="submitForm('ruleFormRef')">搜索</el-button>
                                </el-form-item>
                            </el-form>

                        </div> -->
                    <el-table v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                        style="width: 100%;border-radius: 0.05rem;">
                        <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column prop="school" sortable label="学校全称" width="120" />
                        <el-table-column prop="province" sortable label="省份" />
                        <el-table-column prop="group" sortable label="组别" width="180" />
                        <!-- <el-table-column :key="index" v-for="(item,index) in this.tableData.players" :prop="item.name" sortable :label="item.lable" width="180" /> -->

                        <el-table-column prop="name1" sortable label="参赛选手1" width="180" />
                        <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone1" sortable label="手机号" width="180" />
                        <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                        <el-table-column prop="sex1" sortable label="性别" width="180" />
                        <el-table-column prop="grade1" sortable label="学级" width="180" />
                        <el-table-column prop="profession1" sortable label="专业名称" width="180" />
                        <el-table-column prop="name2" sortable label="参赛选手2" width="180" />
                        <el-table-column prop="id_num2" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone2" sortable label="手机号" width="180" />
                        <el-table-column prop="mail2" sortable label="邮箱" width="180" />
                        <el-table-column prop="sex2" sortable label="性别" width="180" />
                        <el-table-column prop="grade2" sortable label="学级" width="180" />
                        <el-table-column prop="profession2" sortable label="专业名称" width="180" />
                        <el-table-column prop="name3" sortable label="指导教师1" width="180" />
                        <el-table-column prop="phone3" sortable label="手机号" width="180" />
                        <el-table-column prop="mail3" sortable label="邮箱" width="180" />
                        <el-table-column prop="name4" sortable label="指导教师2" width="180" />
                        <el-table-column prop="phone4" sortable label="手机号" width="180" />
                        <el-table-column prop="mail4" sortable label="邮箱" width="180" />
                        <el-table-column prop="name5" sortable label="带队教师" width="180" />
                        <el-table-column prop="phone5" sortable label="手机号" width="180" />
                    </el-table>
                    <div class="pagination-box">
                        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper" :total="this.total">
                            </el-pagination> -->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="产教融合5G+创新应用设计赛" name="fourth">
                    <!-- <el-table v-loading="innovateLoading" border :scrollbar-always-on="true"
                        :data="tableData2"
                        style="width: 100%;border-radius: 0.05rem;">
                        <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column prop="school" sortable label="学校全称" width="120" />
                        <el-table-column prop="province" sortable label="学校所在省份" width="140" />
                        <el-table-column prop="name" sortable label="队名" width="180" />
                        <el-table-column prop="role" sortable label="角色" width="180" />
                        <el-table-column prop="name1" sortable label="姓名" width="180" />
                        <el-table-column prop="id_num" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone" sortable label="手机号" width="180" />
                        <el-table-column prop="mail" sortable label="邮箱" width="180" />
                        <el-table-column prop="sex" sortable label="性别" width="180" />
                        <el-table-column prop="grade" sortable label="学级" width="180" />
                        <el-table-column prop="profession" sortable label="专业名称" width="180" />


                    </el-table> -->
                    <el-table v-loading="innovateLoading" border :scrollbar-always-on="true"
                            :data="tableData2"
                            :row-key="record=>record.team_id"
                            style="width: 100%;border-radius: 0.05rem;">
                            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                            <el-table-column type="expand" width="120">
                                <template #default="props">
                                    <el-table :data="props.row.members">
                                        <el-table-column prop="role" label="角色" width="80"></el-table-column>
                                        <el-table-column prop="name" label="姓名" width="120"></el-table-column>
                                        <el-table-column prop="id_num" label="身份证号"></el-table-column>
                                        <el-table-column prop="phone" label="手机号"></el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column prop="school" sortable label="学校全称"/>
                            <el-table-column prop="province" sortable label="学校所在省份"/>
                            <el-table-column prop="name" sortable label="队名" width="180" />
                            <el-table-column prop="group" sortable label="参赛组别" width="180" />
                        </el-table>
                    <div class="pagination-box">
                        <el-pagination
                            v-model:current-page="this.innovatePage" v-model:page-size="this.innovatePageSize"
                            layout="total, prev, pager, next" :total="this.innovateTotal">
                        </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-card>
        <!-- <el-tabs v-model="activeName" type="border-card" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="待审核信息" name="first" style="position: relative;">
            

            </el-tab-pane> -->
        <!-- </el-tabs> -->
    </div>
    <!-- <seeModelPage ref="dialogModel" :seeVisible="seeVisible" />
    <failModelPage ref="failModel" :failVisible="failVisible" /> -->
</template>
<script>
import axios from 'axios'; // 引入axios
import { Search } from '@element-plus/icons-vue'
import { userInfo } from "api/apis.js";
import upload from '@/components/upload/upload.vue';
import { ElMessage } from 'element-plus'
export default {
    components: {
        Search,
        upload,
    },
    data() {
        return {
            loading: false,
            innovateLoading: false,
            ReviewedLoading: false,
            dialogVisible: false,
            seeVisible: false,
            failVisible: false,
            activeName: 'first',
            activeName1: 'third',
            activeName2: 'five',
            Search: Search,
            // tabPosition:'left',
            formData: {},
            file: '',
            tip: '',
            tableData: [],
            tableData1: [],
            dataList:[],
            tableData2: JSON.parse(sessionStorage.getItem('DATAS')) ? JSON.parse(sessionStorage.getItem('DATAS')) : [],
            formInline: {
                name: '',
            },
            total: 0,//总数居
            page: 1,// 当前多少页
            pageSize: 100,//当前显示多少条数,
            innovateTotal: 0,//总数居
            innovatePage: 1,// 当前多少页
            innovatePageSize: 1000,//当前显示多少条数,
            team_ids: [],
            vate_team_ids: [],
            nums: 0,
            team_nums: 0,
            _gnums: 0,
            teamINums: 0,
            path: '',
            payment_unit_price:120,
            type:'',
            track:'',
            group:'',
        }
    },
    created() {
        this.init_Info()
    },
    methods: {
        submitForm(formEl) {
            if (!formEl) return
            this.$refs[formEl].validate((valid) => {
                if (valid) {
                    this.tableData = []
                    this.getUserNumber()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //初始化
        init_Info() {
            this.getUserNumber();
            this.getUserInnovateNumber();
        },
        handleChildClick(name) {
            if (name.props.name == 'third') {
                this.tableData = []
                this.getUserNumber()
                return
            }
            if (name.props.name == 'fourth') {

                this.getUserInnovateNumber()
                return
            }
        },
        //信息通信工程实践
        getUserNumber() {
            let uid = this.$store.state.User.user_id
            console.log(this.$store.state);
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            dataobj.append("search_name", this.formInline.name);//姓名
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track", '学生');
            dataobj.append("type", '省赛');
            this.loading = true;
            this.tableData = []
            this.team_ids = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.nums = 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                this.dataList = data;
                console.log(JSON.parse(data),'JSON.parse(data)');
                console.log(JSON.parse(this.dataList),'this.dataList');
                if(JSON.parse(data).length > 0 ){
                    let resData = JSON.parse(data)[0];
                    this.type = resData.type;
                    this.track = resData.track;
                    this.group = resData.group;
                }
                let totalNums = [] //计算队员总数
                this.team_nums = +Number(JSON.parse(data).length);
                JSON.parse(data).forEach(m => {
                    totalNums.push(...m.players)
                })
                this.nums = totalNums.length;

                JSON.parse(data).forEach((ele, index) => {
                    let obj = {}
                    this.team_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        }

                    })
                    this.tableData.push(obj);
                })

            })
        },
        getTeamList(item) {
            let leveListP = [];
            let leveListT = [];
            let acc = []
            leveListP.push(...item.players);
            leveListT.push(...item.teachers);
            //队员信息
            leveListP = ele.players.map(accItem => {
                return {
                    school: ele.school,
                    province: ele.province,
                    name: ele.name,
                    role: accItem.role,
                    name1: accItem.name,
                    id_num: accItem.id_num,
                    phone: accItem.phone,
                    mail: accItem.mail,
                    sex: accItem.sex,
                    grade: accItem.grade,
                    profession: accItem.profession
                }
            });
            //教师信息
            leveListT = ele.teachers.map(accItem => {
                return {
                    school: ele.school,
                    province: ele.province,
                    name: ele.name,
                    role: accItem.role,
                    name1: accItem.name,
                    id_num: accItem.id_num,
                    phone: accItem.phone,
                    mail: accItem.mail,
                    sex: accItem.sex,
                    grade: accItem.grade,
                    profession: accItem.profession
                }
            })
            acc = leveListP.concat(...leveListT)
            return acc.map(accItem => {
                return {
                    school: item.school,
                    province: item.province,
                    name: item.name,
                    role: accItem.role,
                    name1: accItem.name,
                    id_num: accItem.id_num,
                    phone: accItem.phone,
                    mail: accItem.mail,
                    sex: accItem.sex,
                    grade: accItem.grade,
                    profession: accItem.profession
                }
            })
        },
        //5G+创新应用实践赛
        getUserInnovateNumber() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("page", 1);//当前页数
            // dataobj.append("limit", this.innovatePageSize);//一页显示多少条
            dataobj.append("search_name", this.formInline.name);//姓名
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track", '创新');
            dataobj.append("type", '省赛');
            this.innovateLoading = true;
            // this.tableData2 = []
            this.vate_team_ids = [];
            sessionStorage.removeItem('DATAS')
            userInfo.selsetUser(dataobj).then(res => {

                this.innovateLoading = false;
                // this.teamINums = Number(count)
                const { count, data } = res;
                console.log(JSON.parse(data))
                this.innovateTotal = count;
                this.tableData2 = JSON.parse(data);
                let totalNums = [];//条数集合
                JSON.parse(data).forEach(m => {
                    totalNums.push(...m.players)
                })
                JSON.parse(data).forEach(ele => {
                    this.vate_team_ids.push(ele.team_id) //保存团队id 
                })
                this._gnums = totalNums.length

            });
        },
        nextStep() {
            var groupDatas = [];
            let total_nums = (this.nums * 1) + (this._gnums * 1);
            let total_team_ids = [] = this.team_ids.concat(...this.vate_team_ids)
            let totalLength = (this.tableData.length * 1) + (this.tableData2.length * 1)
            console.log(totalLength,this.tableData.length)
            let totalObj = {
                nums: total_nums,
                team_nums: totalLength,
            }
            if (totalLength === 0) {
                ElMessage({
                    message: "请先上传报名信息",
                    type: "error",
                });
                return
            } else { 
                    groupDatas = JSON.parse(this.dataList);
                    this.$router.push(
                    { 
                        path: '/ViewDetails', 
                        query:  
                        { 
                            'total_Obj': JSON.stringify(totalObj), 
                            team_ids: total_team_ids,
                            engineeringPractice:this.dataList,
                            innovation:this.tableData2,
                            competition_type_li:this.type,
                            track_li:this.track,
                            group_li:this.group,
                        }
                    })
                
       
                
            }

        },
        callbackValue(file) {
            let uid = this.$store.state.User.user_id
            this.formData = new FormData();//通过form数据格式来传
            this.formData.append("file", file); //选择文件
            this.formData.append("user_id", uid);
            this.formData.append("type", '省赛');

            this.file = file
            userInfo.importUinfo(this.formData).then(res => {
                console.log(res.filename, 'res.filename');
                if (res.filename) {
                    let url = res.filename;
                    let obj = new FormData();//通过form数据格式来传
                    obj.append("file_path_name", url); //文件名
                    axios.post('api/file/download', obj, { responseType: 'blob' }).then(res => {
                        const blob = new Blob([res.data], { type: "application/vnd.ms-excel" })
                        const downloadElement = document.createElement('a');
                        const href = window.URL.createObjectURL(blob); //创建下载的链接
                        downloadElement.href = href;
                        downloadElement.download = 'error_info.xlsx'; //下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); //点击下载
                        document.body.removeChild(downloadElement); //下载完成移除元素
                        window.URL.revokeObjectURL(href); //释放掉blob对象;
                        return
                    })
                }
                this.tip = this.file.name + ' ' + res.msg;
                ElMessage({
                    message: "文件" + res.msg,
                    type: "success",
                });
                this.tableData = []
                this.getUserInnovateNumber();
                this.getUserNumber();

                // this.url = res.file_path_name;
            })
        },
        //文件上传
        getUpload() {

        },
        // 模板下载
        getExcel() {
            debugger
            let a = document.createElement('a');
            let evt = document.createEvent('MouseEvents');
            a.download = `./static/Excel/Template.xlsx`;
            a.href = `./static/Excel/Template.xlsx`;
            evt.initEvent('click', true, true);
            a.dispatchEvent(evt);
            window.URL.revokeObjectURL(a.href);

        },
        //切换当前页
        handleCurrentChange(val) {
            this.tableData = [];
            this.page = val;
            this.getUserNumber();
        },
        //切换条数
        handleSizeChange(pageSize) {
            this.tableData = [];
            this.pageSize = pageSize;
            this.getUserNumber();
        },
        //5g创新当前条数
        handleInnovateSizeChange(pageSize) {
            this.innovatePageSize = pageSize;
            this.getUserInnovateNumber();
        },
        //5g创新当前页数
        handleInnovateCurrentChange(val) {
            this.innovatePage = val;
            this.getUserInnovateNumber();
            // this.tableData2 = JSON.parse(sessionStorage.getItem('DATAS')).slice((this.innovatePage - 1) * this.innovatePageSize, this.innovatePage * this.innovatePageSize);
        }
    }
}
</script>
<style lang="scss" scoped>
.container-box {
    width: 95%;
    margin: auto;
    :deep(.el-card) {
        // height: 8.4rem;
    }
    :deep(.el-tabs__content) {
        // height: 6.8rem;
        // overflow-y: scroll;
    }
}


.formWeight {
    display: flex;
}

.demo-form-inline {
    flex: 3;
}

.decorationBox {

    color: blue;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.right-box {
    display: flex;
    justify-content: left;
    flex: 2
}

.dt_title {
    display: inline-block;
    color: #3D3D3D;
    font-size: 0.22rem;
    margin-right: 0.2rem;
}

.L_btn {
    width: 1.16rem;
    height: 0.32rem;
    border: 0;
    background-color: #C45454;
    border-radius: 0.04rem;
    font-size: 0.14rem;
    color: white;
    // margin-right: 0.2rem;
    cursor: pointer;
}

.R_btn {}

.pagination-box {
    display: flex;
    margin-top: 0.15rem;
}

.el-pagination {
    flex: 2;
}

.step {
    flex: 1;
    display: flex;
    justify-content: right;

    span {
        display: block;
        width: 1.71rem;
        height: 0.45rem;
        font-size: 0.24rem;
        text-align: center;
        line-height: 0.45rem;
        color: white;
        background-color: #436E8A;
        border-radius: 0.04rem;
        cursor: pointer;
    }
}</style>