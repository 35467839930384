<template>
    <div style="text-align: center;">
        <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :limit="1"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeFileUpload"
            :file-list="fileList1"
            :http-request="uploadFile"
            >
            <el-button class="L_btn" slot="trigger">报名信息上传</el-button>
            <template #tip>
                <div style="margin-top:5px;">只能上传格式为xlsx文件,限制文件数量为1</div>
            </template>
            
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
        <div class="tip">{{tip}}</div>
    </div>
</template>
<script>
import { genFileId } from 'element-plus'

  export default {
    props:['callbackFile','tip'],
    data() {
      return {
        tip:this.tip,
        fileList: [],
        fileList1:[]
      };
    },
    methods: {
      submitUpload() {
        this.$refs.upload.submit();
      },
      beforeFileUpload(file,fileList){
      console.log(file)
      let fileName = file.name;
      let uid = file.uid
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      console.log(lastName,lastName.toLowerCase());
      if (
        lastName.toLowerCase() !== ".xls"&&
        lastName.toLowerCase() !== ".xlsx"
      ) {
        this.$message.error("文件必须为.xls .xlsx类型");
        this.$refs['upload'].clearFiles();//清除文件
        return false;
      }
    },
      uploadFile(file) {
        this.fileList1.unshift(file.file)
        let files = file.file
        this.callbackFile(files);
      },
      handleRemove(file, fileList) {
      },
      handleExceed(files,fileList){
        this.$refs['upload'].clearFiles();//清除文件
        const file = files[0]
        file.uid = genFileId()
        this.callbackFile(files[0]);
        this.$refs['upload'].handleStart(files[0]);//选择文件后的赋值方法
      },
      handlePreview(file) {
        console.log(file);
      }
    }
  }
</script>
<style scoped lang="scss">
.select-btn {
    // background: url("../../assets/image/home/select.jpg") no-repeat;
    background-size: 100% 100%;
    margin:0 12px;
}
.L_btn {
    width: 1.16rem;
    height: 0.32rem;
    border: 0;
    background-color: #C45454;
    border-radius: 0.04rem;
    font-size: 0.14rem;
    color:white;
    // margin-right: 0.2rem;
    cursor: pointer;
 }
</style>